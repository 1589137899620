import axios from "@/http/auth";
import { createStore } from "vuex";
import eoiStore from "@/store/eoi/eoiStore";
import authStore from "@/store/auth/authStore";
import profileStore from "@/store/profile/profileStore";
import collegeStore from "@/store/college/collegeStore";
import collegeMandatoryCoursesStore from "@/store/college/collegeMandatoryCoursesStore";
import commonStore from "@/store/common/commonStore";
import adminStore from "@/store/admin/adminStore";
import adminReportMandatoryCourses from "@/store/admin/adminReportMandatoryCourses";
import adminReportCollege from "@/store/admin/adminReportCollege";
import adminIndustriesStore from "@/store/admin/adminIndustriesStore";
import adminEOIStore from "@/store/admin/adminEOIStore";
import industriesStore from "@/store/industries/industriesStore";
import studentStore from "@/store/student/studentStore";
import studentFSStore from "@/store/student/studentFSStore";
import studentCoursesStore from "@/store/student/studentCoursesStore";
import studentCoursesOldStore from "@/store/student/studentCoursesOldStore";
import jobsStore from "@/store/jobs/jobsStore";
import router from "@/router";
import dashboardStore from "@/store/launch/dashboardStore";
import assessmentsStore from "@/store/admin/assessments/assessmentsStore";
import trainingPartners from "@/store/admin/trainings/trainingPartners";
import studentPlacements from "@/store/student/studentPlacements";
import collegeFacultyStore from "@/store/college/collegeFacultyStore";
import collegePlacementOfficerStore from "@/store/college/collegePlacementOfficerStore";
import adminAPIClients from "@/store/admin/api-client/adminAPIClients";
import adminFeedbackStore from "@/store/admin/feedbacks/adminFeedbackStore";
import upscUserRegistrations from "@/store/admin/upsc_registration/upscUserRegistrations";
import adminInternshipsStore from "@/store/admin/internships/adminInternshipsStore";
import internshipStore from "@/store/admin/internships/adminInternship";

export default createStore({
  state: {
    isLoggedIn: false,
    isLoading: false,
    activeUser: {},
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_USER_DATA(state) {
      try {
        const user = localStorage.getItem("user");
        state.activeUser = {
          ...JSON.parse(user || "{}"),
        };
      } catch (e) {
        state.activeUser = {};
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isLoading(state) {
      return state.isLoading;
    },
    activeUser(state) {
      return state.activeUser;
    },
  },
  actions: {
    downloadJSONToCSV(state, { data, filename }) {
      const replacer = (key: any, value: any) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      let csv: any = data.map((row: any) =>
        header
          .map((fieldName: string) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(header.join(","));
      csv = csv.join("\r\n");

      // Create link and download
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
      );
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadStringToCSV(state, { data, filename }) {
      // Create link and download
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(data)
      );
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    checkLoginStatus({ commit, dispatch }) {
      const token = localStorage.getItem("token");
      if (token && token !== "undefined") {
        commit("SET_LOGGED_IN", true);
        dispatch("fetchProfileDetails");
        commit("SET_USER_DATA");
      } else {
        commit("SET_LOGGED_IN", false);
        commit("SET_USER_DATA");
      }
    },
    async logout({ commit, dispatch }, data) {
      try {
        // Prevent multiple logout calls within the same session
        if (sessionStorage.getItem("isLoggingOut")) {
          return;
        }

        sessionStorage.setItem("isLoggingOut", "true");

        const locale = localStorage.getItem("lang");
        const config = {
          method: "post",
          url: "api/v1/logout/",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        let activeUser = localStorage.getItem("user");
        let userId = activeUser ? JSON.parse(activeUser).user_id : "";
        
        localStorage.clear();
        commit("SET_LOGGED_IN", false);
        commit("SET_USER_DATA");

        if (!userId) {
          console.warn("User ID missing, skipping logout API call.");
          
          // Redirect to landing page without calling the logout API
          window.location.href = `${process.env.VUE_APP_NM_LANDING_PAGE_URL}?logout=${encodeURIComponent(true)}`;
          return;
        }

        await axios.post("/api/v1/logout/", { user_id: userId }, config);
        if (locale) {
          localStorage.setItem("lang", locale);
        }
        commit("SET_LOGGED_IN", false);
        commit("SET_USER_DATA");

        if (!window.location.href.includes(process.env.VUE_APP_NM_LANDING_PAGE_URL)) {
          window.location.href = `${process.env.VUE_APP_NM_LANDING_PAGE_URL}?logout=${encodeURIComponent(true)}`;
        } else if (!data) {
          router.push("/");
          window.location.reload();
        }
      } catch (error) {
        console.log("Logout Error: ", error);

        // UNCOMMENT AND CHECK THIS BLOCK LATER
        // if (!localStorage.getItem("user")) {
        //   console.warn("Skipping reload since user was already missing.");
        //   return;
        // }

        localStorage.clear();
        window.location.reload();
      } finally {
        // Remove the flag when done
        sessionStorage.removeItem("isLoggingOut");
      }
    },
  },
  modules: {
    eoiStore,
    authStore,
    profileStore,
    collegeStore,
    collegeMandatoryCoursesStore,
    commonStore,
    adminStore,
    adminReportMandatoryCourses,
    adminReportCollege,
    adminAPIClients,
    studentStore,
    studentFSStore,
    studentCoursesStore,
    studentCoursesOldStore,
    jobsStore,
    dashboardStore,
    adminIndustriesStore,
    adminEOIStore,
    industriesStore,
    assessmentsStore,
    trainingPartners,
    studentPlacements,
    collegeFacultyStore,
    collegePlacementOfficerStore,
    adminFeedbackStore,
    upscUserRegistrations,
    adminInternshipsStore,
    internshipStore,
  },
});
