import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";

const app = createApp(App);

// Google Analytics
// import VueGtag from "vue-gtag";
// app.use(VueGtag, { id: "G-J0K9FK3ZQE" });
// Global Filters

import createFilters from "./filters/filters";
createFilters(app);

// Vue Good Table
import VueGoodTablePlugin from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
app.use(VueGoodTablePlugin);

// tailwind
import "./styles/tailwind.scss";
import "./styles/scss/app.scss";

import i18n from "./i18n";

// ToolTip
// import {TippyPlugin} from 'tippy.vue';
//
// app.use(TippyPlugin);
// app.use(TippyPlugin, {
//     tippyDefaults: {}, // convenience to set tippy.js default props
// })

// ToastNotification
// @ts-ignore
import Toast, { PluginOptions, POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 3000,
};
app.use(Toast, options);

app.use(i18n).use(store).use(router).mount("#app");

app.use(PrimeVue);
