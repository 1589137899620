import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import moment from "moment";

const actions: ActionTree<any, any> = {
  addCollegeBankDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/bank-detail/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCollegeBankDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/bank-details/`, {
          params: data,
        });
        commit("setBanksListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateCollegeBankDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.patch(
          `/api/v1/college/bank-detail/`,
          formData
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getFacultyBankDetails({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/college/faculty/bank-details/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addFacultyBankDetails({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/faculty/bank-details/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateFacultyBankDetails({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.patch(
          `/api/v1/college/faculty/bank-details/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateFacultyStudentAssessmentMarks({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/college/faculty/arts/assessment/",
          data
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  fetchFacultyExternalExaminer({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/faculty/external_examiner/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchStudentForExternalExaminer({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/faculty/student_for_external_examiner/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchSingleCollegeBankDetail({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/bank-detail/`, {
          params: data,
        });
        commit("setCollegeFaculty", response.data.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getInvitationDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/college/registration/invitation-${data.invitationId}/`
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  submitCollegePayment({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/registration/payment-confirmation/`,
          formData
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  performCollegeRegister({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.collegeData).forEach((key: string) => {
        formData.append(key, data.collegeData[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/registration/invitation-${data.invitationId}/`,
          formData
        );
        commit("SET_LOADING", false);
        commit("SET_LOGGED_IN", true);
        const json = response.data;
        commit("saveToken", json.access);
        commit("saveRefreshToken", json.refresh);
        // commit("saveUser", JSON.stringify(json));
        dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sendStudentInvite({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/student/invite/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getStudentsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("resetStudentsData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/student/students/`, {
          params: data,
        });
        // const response = await axios.get(`api/v1/student/filters/students`, { params: data });
        commit("setStudentsListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  downloadStudentsList({ commit, dispatch }, data: any) {
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/student/students/`, {
          params: data,
        });

        let csvJSON = [];
        let students = response.data.students_list;
        for (let i = 0; i < students.length; i++) {
          let status = "";
          switch (students[i].student_current_status) {
            case 1:
              status = "Active";
              break;
            case 2:
              status = "Discontinued";
              break;
            case 3:
              status = "Long absent";
              break;
            case 4:
              status = "Duplicate";
              break;
            default:
              status = "";
              break;
          }
          csvJSON.push({
            "S No": i + 1,
            "NM ID": students[i].invitation_id,
            "EMIS Number": students[i].emis_number,
            "UMIS Number": students[i].umis_number,
            "Roll No": students[i].roll_no,
            "Full Name": students[i].aadhar_number,
            Branch: students[i].rbranch__name || students[i].branch,
            Semester: students[i].sem,
            Email: students[i].email,
            Mobile: students[i].phone_number,
            "Verification Status":
              students[i].verification_status == 0 ? "No" : "Yes",
            "current status": status,
          });
        }
        dispatch("downloadJSONToCSV", {
          data: csvJSON,
          filename: "students_list.csv",
        });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  bulkUploadStudents({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/bulk-upload-file/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  bulkUploadStudentsHistory({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/bulk-upload-file/list/`,
          { params: data }
        );
        commit("setUploadStudentsListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  bulkUploadArtsStudents({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/bulk/upload/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  bulkUploadArtsStudentsHistory({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/bulk/upload/history/`,
          { params: data }
        );
        commit("setUploadArtsStudentsListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  downloadFailureStudentList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/download_bulk_upload_history/${data.id}`
        );
        commit("setStudentsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  approveBulkFileStudents({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/bulk-upload-file/confirmation/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  bulkApproveStudents({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/bulk_students_verify`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  editStudentDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/student/edit/${data.id}`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  SendInvitation({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/student/send_invite/${data.id}`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  submitStudentVerificationRequest({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/details/${data.id}/`,
          formData
        );
        commit("setStudentsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateCollegePrincipalDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
        formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/college/details/${data.id}/`,
          formData
        );
        dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getStudentsDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/student/details/${data.studentId}`
        );
        commit("setStudentsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  approveRejectStudentCertificate({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/details/${data.id}/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  sendStudentsBulkInvitations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/invite/bulk/`,
          formData
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  uploadedStudentList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/download_bulk_upload_history`,
          formData
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getHackathonStudents({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/college/hackathon/registrations/`,
          { params: data }
        );
        commit("setStudentList", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getHackathonFilters({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/hackathon/filters/`, {
          params: data,
        });
        commit("setStudentFilter", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  enrollForEvent({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/hackathon/enroll/`, {
          params: data,
        });
        commit("setStudentEnrolled", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  unEnrollForEvent({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.delete(
          `/api/v1/college/hackathon/enroll/`,
          { params: data }
        );
        commit("setStudentEnrolled", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getChangesSpoclist({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/college/get_change_spoc_list/`,
          { params: data }
        );
        commit("setChangespocData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateSpocDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/college/update_spoc_details/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  listSpocDetails({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/list_spoc_details/`, {
          params: data,
        });
        resolve(response.data);
        commit("setSpocDetails", response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const collegeStore: Module<any, any> = {
  state: () => ({
    students: [],
    uploadSHistory: [],
    student: {},
    studentTotalPages: 0,
    bankTotalPages: 0,
    uploadSTotalPages: 0,
    uploadSPage: 0,
    bankPage: 0,
    unverifiedStudentsCount: 0,
    studentsCount: 0,
    active_students_count: 0,
    discontinued_students_count: 0,
    longab_students_count: 0,
    duplicate_students_count: 0,
    limit: 20,
    page: 0,
    banks: [],
    spocs: [],
    listSpoc: [],
  }),
  mutations: {
    setStudentsData(state, data) {
      state.student = data;
    },
    resetStudentsData(state) {
      state.students = [];
      state.yearOfStudyFilters = [];
      state.active_students_count = 0;
      state.discontinued_students_count = 0;
      state.longab_students_count = 0;
      state.duplicate_students_count = 0;
      state.studentsCount = 0;
      state.studentTotalPages = 0;
      state.limit = 20;
      state.page = 0;
    },
    setStudentsListData(state, data) {
      state.students = data.students_list;
      state.yearOfStudyFilters = data.filters.years;
      state.active_students_count = data.active_students_count;
      state.discontinued_students_count = data.discontinued_students_count;
      state.longab_students_count = data.longab_students_count;
      state.duplicate_students_count = data.duplicate_students_count;
      state.studentsCount = data.total_count;
      state.unverifiedStudentsCount = data.total_unverified_students_count;
      state.studentTotalPages = Math.ceil(data.total_count / data.limit);
      state.limit = data.limit;
      state.page = data.page;
    },
    setUploadStudentsListData(state, data) {
      state.uploadSHistory = data.temporary_files;
      state.uploadSTotalPages = Math.ceil(data.count / data.limit);
      state.uploadSPage = data.page;
    },
    setUploadArtsStudentsListData(state, data) {
      state.uploadSHistory = data.records;
      state.uploadSTotalPages = Math.ceil(data.count / data.limit);
      state.uploadSPage = data.page;
    },
    setBanksListData(state, data) {
      console.log("setbankData", data.bank_list);
      state.banks = data.bank_list;
      state.bankTotalPages = Math.ceil(data.total_count / data.limit);
      state.limit = data.limit;
      state.bankPage = data.page;
    },
    setChangespocData(state, data) {
      console.log("setChangespocData", data.data);
      state.spocs = data.data;
      state.spocsTotalPages = Math.ceil(data.total_count / data.limit);
      state.limit = data.limit;
      state.spocsPage = data.page;
    },
    setSpocDetails(state, data) {
      state.listSpoc = data.data;
    },
  },
  actions,
  getters: {
    uploadStudentsList(state) {
      return state.uploadSHistory;
    },
    uploadStudentsTotalPages(state) {
      return state.uploadSTotalPages;
    },
    uploadStudentsPage(state) {
      return state.uploadSPage;
    },
    collegeStudentsList(state) {
      return state.students;
    },
    collegeStudentsCount(state) {
      return state.studentsCount;
    },
    collegeActiveStudentsCount(state) {
      return state.active_students_count;
    },
    collegeDiscontinuedStudentsCount(state) {
      return state.discontinued_students_count;
    },
    collegeLongabStudentsCount(state) {
      return state.longab_students_count;
    },
    collegeDuplicateStudentsCount(state) {
      return state.duplicate_students_count;
    },
    collegeUnverifiedStudentsCount(state) {
      // return 0;
      return state.unverifiedStudentsCount;
    },
    studentsYearOfStudyFilters(state) {
      return state.yearOfStudyFilters;
    },
    studentTotalPages(state) {
      return state.studentTotalPages;
    },
    studentPage(state) {
      return state.page;
    },
    collegeStudent(state) {
      return state.student;
    },
    adminBankList(state) {
      return state.banks;
    },
    banksTotalPages(state) {
      return state.bankTotalPages;
    },
    banksPage(state) {
      return state.bankPage;
    },
    spocsList(state) {
      return state.spocs;
    },
    spocsTotalPages(state) {
      return state.spocsTotalPages;
    },
    spocsPage(state) {
      return state.spocsPage;
    },
    listSpoc(state) {
      return state.listSpoc;
    },
  },
};

export default collegeStore;
