import axios1 from "axios";
import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const newAxios = axios1.create({
  baseURL: 'https://naanmudhalvan.tn.gov.in/'
});

const actions: ActionTree<any, any> = {
  getIndustryJobs({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/job/list/`, { params: data });
        commit("setIndustryJobsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryJob({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/job/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryJobFair({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/add_job_requirement/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addUpdateJobFair({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/placements/add_update_job_fair/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  assignCollegeDistrictJobFair({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/placements/assign_job_fair/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getIndustryJobAddedData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/fetch_job_requirement/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryInternshipAddedData({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/fetch_internship_data/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getIndustryInternAddedData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/fetch_intern_requirement/`, formData);
        commit("setInternData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryInternReportData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/fetch_intern_report/`, formData);
        commit("setInternReport", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllIndustryJobData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/fetch_all_job_requirement/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryInternships({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/internship/list/`, { params: data });
        commit("setIndustryInternshipsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryInternship({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/internship/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryInternshipWithOutLogin({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/internship/add/w-login/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  editIndustryInternship({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/internship/edit/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  editlistIndustryInternship({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/internship/editList/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryMentorship({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/mentorship/list/`, { params: data });
        commit("setIndustryMentorshipData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryMentorship({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/mentorship/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryTrainings({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/training/list/`, { params: data });
        commit("setIndustryTrainingData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewIndustryTraining({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/training/add/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  fetchSectorsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/placements/sectors-list/`, { params: data });
        commit("setSectorsList", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  registerNewOrganization({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/industry/industry_job_registration/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  fetchJobFairList({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/placements/get_job_fair/`, formData);
        commit("setJobFairList", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  enrollJobFairForIndustry({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/placements/map_job_fair/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getJobFairStudentsList({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(`/reports/api/placements/getJobFairStudentList.php`, formData);
        commit("setJobFairStudentsList", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  updateStudentJobFairAttendance({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/placements/update_industry_attendance/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getIndustryJobTitles({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/get_job_tile`, { params: data });
        commit("setIndustryJobTitles", response);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  getInternshipSubmittedData({ commit },data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/internship/student_data/`, { params: data});
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  intershipInterestedStudentEntry({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
  
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
  
      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/industry/internship/interest/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  getEligibleForInternship({ commit },data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/internship/eligible_student/`, { params: data});
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const industriesStore: Module<any, any> = {
  state: () => ({
    iJobs: [],
    Listintern:[],
    ListinternPages:0,
    Reportintern:[],
    jTotalPages: 0,
    jPage: 0,
    iInternships: [],
    iTotalPages: 0,
    iPage: 0,
    iMentorship: [],
    mTotalPages: 0,
    mPage: 0,
    iTrainings: [],
    tTotalPages: 0,
    tPage: 0,
    jobFairList: [] as any,
  }),
  mutations: {
    resetData(state) {
      // state.iJobs = [];
      // state.jTotalPages = 0;
      // state.jPage = 0;
    },
    setInternData(state, data) {
      state.Listintern = data.data;
      //state.totalPages = Math.ceil(data.filters.total_count / data.filters.limit);
      //state.limit = data.filters.limit;
      //state.page = data.filters.page;
  },
  setInternReport(state, data) {
    state.Reportintern = data.data;
    //state.totalPages = Math.ceil(data.filters.total_count / data.filters.limit);
    //state.limit = data.filters.limit;
    //state.page = data.filters.page;
},
    setIndustriesData(state, data) {
      state.industries = data.industries;
      state.totalPages = Math.ceil(data.filters.total_count / data.filters.limit);
      state.limit = data.filters.limit;
      state.page = data.filters.page;
  },
    setIndustryJobsData(state, data) {
        state.iJobs = data.internships;
        state.jTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.jPage = data.filters.page;
    },
    setIndustryInternshipsData(state, data) {
        state.iInternships = data.internships;
        state.iTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.iPage = data.filters.page;
    },
    setIndustryMentorshipData(state, data) {
        state.iMentorship = data.mentors;
        state.mTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.mPage = data.filters.page;
    },
    setIndustryTrainingData(state, data) {
        state.iTrainings = data.internships;
        state.tTotalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.tPage = data.filters.page;
    },
    setSectorsList(state, data) {
      state.sectorsList = data.data;
    },
    setJobFairList(state, data) {
      state.jobFairList = data.data;
    },
    setJobFairStudentsList(state, data) {
      state.jobFairStudentsList = data ? data.data[0].allStudents : [];
    },
    setIndustryJobTitles(state, data) {
      state.industryJobTitles = data.data;
    }
  },
  actions,
  getters: {
    adminInternList(state) {
      return state.Listintern;
    },
    adminInternReport(state) {
      return state.Reportintern;
    },
    iJobs(state) {
        return state.iJobs;
    },
    iJTotalPages(state) {
      return state.jTotalPages;
    },
    ijPage(state) {
      return state.jPage;
    },
    iInternships(state) {
        return state.iInternships;
    },
    iITotalPages(state) {
      return state.iTotalPages;
    },
    iIPage(state) {
      return state.iPage;
    },
    iMentorship(state) {
        return state.iMentorship;
    },
    iMTotalPages(state) {
      return state.mTotalPages;
    },
    iMPage(state) {
      return state.mPage;
    },
    iTrainings(state) {
        return state.iTrainings;
    },
    iTTotalPages(state) {
      return state.tTotalPages;
    },
    iTPage(state) {
      return state.tTotalPages;
    },
    sectorsList(state) {
      return state.sectorsList;
    },
    jobFairList(state) {
      return state.jobFairList;
    },
    jobFairStudentsList(state) {
      return state.jobFairStudentsList;
    },
    jobFairTitleOptions(state) {
      const currentDate = new Date();
      const currentDateString = currentDate.toISOString().split('T')[0]; // Today's date

      return state.jobFairList
        .filter(
          (item: any) =>
            item.enrolled &&
            new Date(item.start_date).toISOString().split("T")[0] <=
              currentDateString
        )
        .map((item: any) => ({
          text: item.jobfair_title,
          value: item.id,
        }));

      // MATCHING STARTDATE FOR ALLOWING TOMORROW'S DATE TOO
      // const nextDate = new Date(currentDate);
      // nextDate.setDate(currentDate.getDate() - 1);
      // const nextDateString = nextDate.toISOString().split('T')[0];

      // return state.jobFairList
      //   .filter((item: any) => item.enrolled && 
      //     (new Date(item.start_date).toISOString().split('T')[0] === currentDateString || 
      //     new Date(item.start_date).toISOString().split('T')[0] === nextDateString))
      //   .map((item: any) => ({
      //     text: item.jobfair_title,
      //     value: item.id
      //   }));
    },
    industryJobTitles(state) {
      return state.industryJobTitles;
    }
  },
};

export default industriesStore;
