import axios from "axios";
import axios1 from "@/http/http";
import { Module, ActionTree } from "vuex";
import { baseUrl } from "@/http/global_urls";

const newAxios = axios.create({
  baseURL: "https://naanmudhalvan.tn.gov.in/",
});

const newAxiosForJson = axios.create({
  baseURL: baseUrl,
});
// const newAxios1 = axios.create({
//     baseURL: 'https://opsportal.hiremee.co.in/'
// });

const actions: ActionTree<any, any> = {
  getAllTrainingPartners({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/getTrainingPartners.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getFDPDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/fdp/getFDPDetails.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  updateFDPDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/fdp/updateFDPDetails.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getCollegeFacultiesList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/fdp/getAllFaculties.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getTPLoginCheck({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/tpLoginCheck.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  updateTPPassword({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/tpPasswordUpdate.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  kpDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get("/api/v1/kp/kp_list/", {
          params: data,
        });
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  generateFacultyCertificate({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get(
          "/api/v1/college/faculty/certificate_generation/",
          { params: data }
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpEnrolledStudents({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.post("api/v1/kp/student_list/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpEnrolledStudentsCount({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/tpEnrolledStudentCount.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpStudentsFilter({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.post("/api/v1/kp/list_filter/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpPostAttendance({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        formData.append(key, JSON.stringify(data[key])); // Ensure data[key] is stringified
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          "/api/v1/kp/update_attendance/",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpStudentAttendance({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/tpStudentAttendance1.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpUpdateStudentAttendance({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/tpUpdateStudentAttendance.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  fdpEnrolledStudents({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/fdp/getAllStudents.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  facultyUpdateAttendance({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/fdp/facultyAttendanceUpdate.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpStudentMarksUpdate({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/tpStudentMarksUpdate.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpPostMarks({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          "/api/v1/kp/update_mark_all/",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpUpdateMarks({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await axios1.post("/api/v1/kp/update_mark/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpAttendanceDateChange({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/tpAttendanceDateChange.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  // getStudentBranchDetails({commit, dispatch}, data: any) {
  //     // eslint-disable-next-line no-async-promise-executor
  //     return new Promise(async (resolve, reject) => {
  //         const formData = new FormData();

  //         Object.keys(data).forEach((key: string) => {
  //             formData.append(key, data[key]);
  //         });
  //         commit("SET_LOADING", true);
  //         try {
  //             const response = await newAxios.post("/reports/api/profile/getBranchDetails.php", formData);
  //             commit("SET_LOADING", false);
  //             resolve(response.data);
  //         } catch (err: any) {
  //             commit("SET_LOADING", false);
  //             try {
  //                 reject(err.response.data.message);
  //             } catch (e) {
  //                 reject(err.message);
  //             }
  //         }
  //     });
  // },

  getPsychometricTestToken({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        console.log({ data });
        const response = await newAxios.post(
          "hiremee/generate-token/",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        console.log({ newAxios });
        commit("SET_LOADING", false);
        try {
          console.log({ newAxios });
          reject(err.response.data.message);
        } catch (e) {
          console.log({ newAxios });
          reject(err.message);
        }
      }
    });
  },

  technosmileRegistration({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        console.log({ data });
        const response = await newAxios.post(
          "/reports/api/profile/technosmileRegistration.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        console.log({ newAxios });
        commit("SET_LOADING", false);
        try {
          console.log({ newAxios });
          reject(err.response.data.message);
        } catch (e) {
          console.log({ newAxios });
          reject(err.message);
        }
      }
    });
  },

  updateLinkedinProfileLink({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        console.log({ data });
        const response = await newAxios.post(
          "/reports/api/profile/updateLinkedinProfileLink.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        console.log({ newAxios });
        commit("SET_LOADING", false);
        try {
          console.log({ newAxios });
          reject(err.response.data.message);
        } catch (e) {
          console.log({ newAxios });
          reject(err.message);
        }
      }
    });
  },

  // getPsychometricTest({commit, dispatch}, data: any) {
  //     // eslint-disable-next-line no-async-promise-executor
  //     return new Promise(async (resolve, reject) => {
  //         const formData = new FormData();

  //         Object.keys(data).forEach((key: string) => {
  //             formData.append(key, data[key]);
  //         });
  //         commit("SET_LOADING", true);
  //         try {
  //             // const response = await newAxios1.post("api/v16/test-initiate", formData);

  //             const config = {
  //                 method: 'post',
  //                 url: 'https://api.hiremee.co.in/api/v16/test-initiate',
  //                 headers: {
  //                     'Authorization': `Bearer ${token}`
  //                 },
  //                 data: formData,
  //             };
  //             const response = await axios(config);

  //             commit("SET_LOADING", false);
  //             resolve(response.data);
  //         } catch (err: any) {
  //             commit("SET_LOADING", false);
  //             try {
  //                 reject(err.response.data.message);
  //             } catch (e) {
  //                 reject(err.message);
  //             }
  //         }
  //     });
  // },

  loginSession(context, data) {
    sessionStorage.setItem("session", JSON.stringify(data));
    context.commit("setSession", data);
  },

  getSession(context) {
    const session = sessionStorage.getItem("session");
    if (session && typeof session === "string" && session !== "") {
      const data = JSON.parse(session);
      context.commit("setSession", data);
    }
  },

  logoutSession(context) {
    context.commit("logoutSession");
  },

  addLinkedInProfile({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/profile/addLinkedInProfile.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getLinkedInProfile({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/profile/getLinkedInProfile.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  uploadBadgeImage({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // const formData = new FormData();

      // Object.keys(data).forEach((key: string) => {
      //     formData.append(key, data[key]);
      // });
      // commit("SET_LOADING", true);
      try {
        // const response = await newAxios.post("/reports/api/profile/uploadBadgeImage.php", formData);

        const config = {
          method: "post",
          url: "/reports/api/profile/uploadBadgeImage.php",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        const response = await newAxios(config);

        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  removeBadgeImage({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/profile/removeBadgeImage.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  removeSkill({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/profile/removeSkill.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getAllSkills({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/profile/getAllLinkedinSkills.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getSyllabus({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/profile/getSkillofferingSyllabus.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getCourseStatus({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/psychometric_test/getStudentCourseStatus.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getCollegeLevelStatus({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/psychometric_test/getCollegeStatus.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getPsychometricColleges({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/psychometric_test/getCollegesList.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getPsychometricStatusCount({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/psychometric_test/getPsychometricStatusCount.php",
          formData
        );
        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getEnrolledReport({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/mandatory_courses/getEnrolledReport.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getEnrolledListCSV({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/mandatory_courses/getEnrolledListCSV.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  performEOISubmission_st1({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/eoi_registration_step1.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  performEOISubmission_st2({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/eoi_registration_step2.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  performEOISubmission_st3({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/eoi_registration_step3.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  performEOISubmission_st4({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/eoi_registration_step4.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getPreviousExpDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/getPreviousExpDetails.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  deletePreviousExpDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/deletePreviousExpDetails.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getProposedCourses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/getProposedCourses.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  deleteProposedCourses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/deleteProposedCourses.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  deleteTrainerDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/deleteTrainerDetails.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  get_EOI_Courses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/get_EOI_Courses.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  get_EOI_Sectors({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/get_EOI_Sectors.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  get_EOI_SectorsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/get_EOI_SectorsList.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  uploadDocFile({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // const formData = new FormData();

      // Object.keys(data).forEach((key: string) => {
      //     formData.append(key, data[key]);
      // });
      // commit("SET_LOADING", true);
      try {
        // const response = await newAxios.post("/reports/api/profile/uploadBadgeImage.php", formData);

        const config = {
          method: "post",
          url: "/reports/api/EOI/uploadDocFile.php",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        const response = await newAxios(config);

        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getEOIDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/getEOIDetails.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getEOIRegistrations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/admin/getAllList.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  deleteEOIRegistration({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/admin/deleteEOI.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getEOIPrevExpDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/admin/getPrevExpList.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getEOIPropCourses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/admin/getPropCoursesList.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getEOITrainerDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/admin/getTrainerList.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  updateDocURL({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/EOI/updateEOIDocument.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getFDPMappingCount({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/fdp/facultyCourseMappingCount.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getFDPMappingData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/fdp/facultyCourseMappingData.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpFacultiesNominated({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/Nominated_Faculties.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  tpFacultiesMarkUpdate({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/trainings/tp/updateFacultyMark.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sendKpOTP({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(`/api/v1/kp/otp/send/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  verifyKpOTP({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          "/api/v1/kp/otp/confirmation/",
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateKpData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios1.patch(
          `/api/v1/kp/${data.kp_id}/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  sigaramThoduPerformEOISubmissionStep1({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/eoi_registration_step1.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduPerformEOISubmissionStep2({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/eoi_registration_step2.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduPerformEOISubmissionStep3({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/eoi_registration_step3.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduPerformEOISubmissionStep4({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/eoi_registration_step4.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduGetPreviousExpDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/getPreviousExpDetails.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduDeletePreviousExpDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/deletePreviousExpDetails.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduGetProposedCourses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/getProposedCourses.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduDeleteProposedCourses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/deleteProposedCourses.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduDeleteTrainerDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/deleteTrainerDetails.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduGetEOIDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/getEOIDetails.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduGetEOIPrevExpDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/admin/getPrevExpList.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduGetEOIPropCourses({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/admin/getPropCoursesList.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduGetEOITrainerDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/admin/getTrainerList.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduUploadDocFile({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      // const formData = new FormData();

      // Object.keys(data).forEach((key: string) => {
      //     formData.append(key, data[key]);
      // });
      // commit("SET_LOADING", true);
      try {
        // const response = await newAxios.post("/reports/api/profile/uploadBadgeImage.php", formData);

        const config = {
          method: "post",
          url: "/reports/api/sigaramthodu_eoi/uploadDocFile.php",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        const response = await newAxios(config);

        // commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        // commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduUpdateDocURL({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/updateEOIDocument.php",
          formData
        );

        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduGetEOIRegistrations({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await newAxios.post(
          "/reports/api/sigaramthodu_eoi/admin/getAllList.php",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  // generateToken({commit, dispatch}, data: any) {
  //     // eslint-disable-next-line no-async-promise-executor
  //     return new Promise(async (resolve, reject) => {
  //         const formData = new FormData();

  //         Object.keys(data).forEach((key: string) => {
  //             formData.append(key, data[key]);
  //         });
  //         commit("SET_LOADING", true);
  //         try {
  //             const response = await newAxios1.post("/api/generate-token", formData);
  //             commit("SET_LOADING", false);
  //             resolve(response.data);
  //         } catch (err: any) {
  //             commit("SET_LOADING", false);
  //             try {
  //                 reject(err.response.data.message);
  //             } catch (e) {
  //                 reject(err.message);
  //             }
  //         }
  //     });
  // },

  getAllTrainerScheduleList({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get(
          `/api/v1/student/get_trainer_schedule_details_by_tp_id/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllTrainerScheduleListForNonTp({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          `/api/v1/student/get_trainer_schedule_details/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  saveTrainerScheduleData({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const config = {
          method: "post",
          url: "/api/v1/student/store_trainer_schedule_records/",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await newAxiosForJson(config);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  getTrainerScheduleWeeklyPlanList({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get(
          `/api/v1/student/get_trainer_schedule_weekly_plan_list/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getTrainerScheduleExistence({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          `/api/v1/student/get_trainer_weekly_plan/`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getKpTrainersList({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get(
          `/api/v1/student/get_trainers_by_knowledge_partner/`,
          { params: data }
        );
        commit("SET_KP_TRAINERS_LIST", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  addTrainerDetails({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          "api/v1/kp/add_trainer_details/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  saveProblemStatementData({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          "/api/v1/kp/add_update_problem_statement/",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
  getProblemStatementList({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get(
          `/api/v1/kp/get_problem_statements/`,
          { params: data }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  approveRejectProblemStatementRes({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.put(
          "/api/v1/kp/update_problem_statement_res_status/",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  approveRejectProblemStatement({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.put(
          "/api/v1/student/update_problem_statement_status/",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  // generateToken({commit, dispatch}, data: any) {
  //     // eslint-disable-next-line no-async-promise-executor
  //     return new Promise(async (resolve, reject) => {
  //         const formData = new FormData();

  //         Object.keys(data).forEach((key: string) => {
  //             formData.append(key, data[key]);
  //         });
  //         commit("SET_LOADING", true);
  //         try {
  //             const response = await newAxios1.post("/api/generate-token", formData);
  //             commit("SET_LOADING", false);
  //             resolve(response.data);
  //         } catch (err: any) {
  //             commit("SET_LOADING", false);
  //             try {
  //                 reject(err.response.data.message);
  //             } catch (e) {
  //                 reject(err.message);
  //             }
  //         }
  //     });
  // },

  trainerDetailsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get("/api/v1/kp/view_trainer_details/", {
          params: data,
        });
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  editTrainerDetails({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          "api/v1/kp/edit_trainer_details/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },

  // MOVE THIS TO NEW TRAINER STORE TS FILE

  getAllTrainingScheduleListForTrainer({ commit }, data: any) {
    let trainerId = data.trainerId;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get(`api/v1/kp/${trainerId}/schedule/`);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getStudentsListForTrainingAttendance({ commit }, data: any) {
    let plannedWeekId = data.plannedWeekId;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios1.get(
          `api/v1/kp/${plannedWeekId}/attendance/`
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  submitStudentAttendanceForTrainer({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const config = {
          method: "post",
          url: "api/v1/kp/mark_attendance/",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: data,
        };

        const response = await newAxiosForJson(config);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  uploadHeadcountImageForStudentAttendance({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios1.post(
          "api/v1/kp/count-people/",
          formData
          // {
          //   headers: {
          //     "Content-Type": "multipart/form-data",
          //   },
          // }
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const trainingPartners: Module<any, any> = {
  state: () => ({
    token: "",
    status: "",
    username: "",
    data: {},
    kpTrainersList: [],
  }),
  mutations: {
    setSession(state, n) {
      state.token = n.jwt;
      state.status = n.status;
      state.username = n.username;
      state.data = n.data[0];
    },

    logoutSession(state) {
      sessionStorage.clear();
      state.token = "";
      state.status = "";
      state.username = "";
      state.data = {};
    },

    SET_KP_TRAINERS_LIST(state, data) {
      state.kpTrainersList = data.data;
    },
  },
  actions,
  getters: {
    getLogin(state) {
      return typeof state.token === "string" && state.token !== "";
    },
    getToken(state) {
      return state.token;
    },
    getUserId(state) {
      return state.username;
    },
    getUserData(state) {
      return state.data;
    },
    kpTrainersList(state) {
      return state.kpTrainersList || [];
    },
  },
};

export default trainingPartners;
